import React from 'react'
import "../../styles/main.css";
import "../../styles/responsive.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import legal_doc from "../../assets/documents/nuestro_privacy_policy.pdf";
import Footer from '../../components/footer/english';
import NavBar from '../../components/navbar/english';


const Legal_Four = () => {
  return (
    <div>
        <NavBar/>
        <section className="main-slider pt-5" style={{backgroundColor: '#014384', color:'#ffffff', padding:'0 8%', textAlign: 'justify'}}>
        
        <h1 style={{fontFamily: 'RockoUltraFLF'}} className="text-center">Nuestro Privacy Policy</h1><br/><br/>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>

  <p style={{fontFamily: 'Avenir', fontSize: '15px', color: '#ffffff', margin: '0'}}>Effective Date: May 1, 2021</p>

  <a href={legal_doc} download="nuestro_privacy_policy.pdf" style={{textAlign: 'right', fontSize: '14px', color: '#ffffff', textDecoration: 'none', border:'2px solid rgb', backgroundColor:'rgb(232, 0, 120)',padding:'8px 15px', borderRadius:'20px' }}>Download pdf</a>

</div>
        
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>This policy describes how Nuestro Wallet (Nuestro Wallet, “we,” “us,” or the Nuestro Wallet) collects, aggregates, stores, safeguards and uses the data and information (including non-public personal information, or “NPI”) provided by users through our website, [<a href="https://www.nuestro.info" target="_blank" rel="noopener" style={{color:'rgb(232,0,120)'}}>www.nuestro.info</a>] (the “Site”), as well as information collected by us through other means, including by email, over the phone, or in offline communications. This Site is operated by Fintech and has been created to provide information about our Fintech, products, and services (together, the “Services”). </p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We take your privacy and the security of your information seriously. </p>

        <h6 style={{padding:'1% 0'}}>This policy explains:</h6>
        <ul style={{padding:'0 4%'}}>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>What information we collect, and how we use it</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>Choices you can make about the way your information is collected and used</li>
            <li style={{padding:'0.5% 0', fontSize: '18px', fontFamily: 'Avenir', listStyleType: 'disc'}}>How we protect personal information electronically and physically</li>
        </ul>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>This policy is incorporated into and a material term of your registration and/or use of Fintech’s products and services, including our website, [<a href="https://www.nuestro.info" target="_blank" rel="noopener" style={{color:'rgb(232, 0, 120)'}}>www.nuestro.info</a>].  By using the Site or Services, you consent to the practices set forth in this Privacy Policy.
<br/><br/>
When the Privacy Policy mentions “Company,” “we,” “us,” or “our,” it refers to the Fintech Name, a Tennessee Limited Liability Company and the Nuestro Financial LLC. you are contracting with in this policy. Nuestro Financial, LLC referred to herein as “Fintech” unless the context requires otherwise.
</p>
        <h4 style={{padding:'1% 0'}}>INFORMATION WE COLLECT</h4>
        <h6 style={{padding:'1% 0'}}>Information You Provide to Us</h6>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Fintech collects information from you when you choose to provide it to us through the Site or through any other means. This may include when you create an account on the Site, register or request products or services, request information from us, sign up for newsletters or our email lists, use our Site, or otherwise contact us.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>The information we collect may include your name, address, email address, telephone or mobile phone number, information relating to your employment or professional activities and financial account information. You may be required to provide certain personal and/or business information to apply for and receive Fintech products services.</p>
        <h6 style={{padding:'1% 0'}}>Information We Automatically Collect</h6>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We may use cookies or other technologies to automatically collect certain information when you visit our Site or interact with our emails. For example, if you are responding to an offer, promotional email or other email from us, we may automatically populate your personal information into our system once you enter your offer code or similar identifying device or otherwise accept your offer or promotion. Additionally, we may automatically collect certain non-personal information from you such as your browser type, operating system, software version, and Internet Protocol ("IP") address. We also may collect information about your use of the Site, including the date and time of access, the areas or pages that you visit, the amount of time you spend using the Site, the number of times you return, whether you open, forward, or click-through emails, and other Site usage data.</p>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>You may adjust your browser or operating system settings to limit this tracking or to decline cookies, but by doing so, you may not be able to use certain features on the Site or take full advantage of all of our offerings. Check the "Help" menu of your browser or operating system to learn how to adjust your tracking settings or cookie preferences. Please note that our system may not respond to Do Not Track requests or headers from some or all browsers.</p>

        <h4 style={{padding:'1% 0'}}>HOW WE USE INFORMATION WE COLLECT</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Fintech uses the data and information you provide in a manner that is consistent with this Privacy Policy and applicable law. If you provide personal data for a certain reason, we may use the personal data in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the personal data you provide to answer your question or resolve your problem. Also, if you provide personal data in order to obtain access to the Site or Services, we will use your personal data to provide you with access to such services and to monitor your use of such services. </p>

        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Fintech may also use your personal data and other personally non-identifiable information collected through the Site or the provision of the Services to help us improve the content and functionality of the Site or the Services, to better understand our users and to improve the Site and the Services. Fintech and its affiliates may use this information to contact you in the future to tell you about services we believe will be of interest to you. If at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below.</p>
        <h4 style={{padding:'1% 0'}}>SHARING OF INFORMATION WE COLLECT</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Fintech is not in the business of selling your information.  There are, however, certain circumstances in which we may share your personal data with certain third parties without further notice to you, as set forth below:</p>
        <h6 style={{padding:'1% 0'}}>Agents, Consultants, and Third-Party Service Providers:</h6>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Fintech, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases, and processing payments. When we employ another entity to perform a function of this nature, we only provide them with the information that they need to perform their specific function.</p>
        <h6 style={{padding:'1% 0'}}>Business Transfers:</h6>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, personal data may be part of the transferred assets.</p>
        <h6 style={{padding:'1% 0'}}>Related Companies:</h6>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We may also share your personal data with our corporate affiliates and subsidiaries, if any, for purposes consistent with this Privacy Policy.</p>
        <h6 style={{padding:'1% 0'}}>Legal Requirements:</h6>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Fintech may disclose your personal data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of Fintech, (iii) act in urgent circumstances to protect the personal safety of users of the Site, the Services or the public, or (iv) protect against legal liability.</p>

        <h4 style={{padding:'1% 0'}}>LINKS TO OTHER WEBSITES</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>The Site may have links to third-party websites, which may have privacy policies that differ from our own. We are not responsible for the practices of such sites, nor does any such link imply that Fintech endorses or has reviewed the third party site subject to such link. We suggest contacting those sites directly for information on their privacy policies.</p>
        <h4 style={{padding:'1% 0'}}>CHILDREN AND MINORS</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Fintech does not knowingly collect personal data from minors under the age of 18. If you are under the age of 18, please do not submit any personal data through the Services. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide personal data without their permission. If you have reason to believe that a minor under the age of 18 has provided personal data to Fintech through the Site or the Services, please contact us, and we will endeavor to delete that information from our databases.</p>

        <h4 style={{padding:'1% 0'}}>DATA SECURITY</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>We have taken certain physical, administrative, and technical steps to safeguard the information we collect from and about our customers and Site visitors. While we make reasonable efforts to help ensure the integrity and security of our network and systems, we cannot guarantee our security measures.  Therefore, you should take special care in deciding what information you send to us via email. Please keep this in mind when disclosing any personal data to the Fintech via the Internet.</p>
        <h4 style={{padding:'1% 0'}}>ACCESS TO YOUR PERSONAL INFORMATION</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>To keep your personal data accurate, current, and complete, please contact us as specified below. We will take reasonable steps to update or correct personal information in our possession that you have previously submitted via the Services.</p>
        <h6 style={{padding:'1% 0'}}>Who Can I Contact at Fintech Regarding Data Protection Issues?</h6>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}> Fintech has designated a Data Protection Officer to assist with data privacy and data protection issues.  You may contact him or her by emailing  <a href="contact@nuestro.info" style={{color:'rgb(232,0,120)'}}>contact@nuestro.info</a> and addressing your questions or concerns to the Data Protection Officer. </p>
        <h4 style={{padding:'1% 0'}}>IF YOU HAVE QUESTIONS</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>If you have any questions about this Privacy Statement or the practices described herein, you may contact us at: <a href="contact@nuestro.info" style={{color:'rgb(232,0,120)'}}>contact@nuestro.info</a></p>
        <h4 style={{padding:'1% 0'}}>CHANGES TO THIS STATEMENT</h4>
        <p style={{fontFamily: 'Avenir', fontSize: 'large', color:'#ffffff'}}>Fintech reserves the right to revise this Privacy Policy at any time. When we do, we will post the change(s) on the Site. This Privacy Policy was last updated on the date indicated above. Your continued use of the Site and the Services after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.</p>
        
        <br/><br/>
        <div className="text-right"><i style={{fontSize: '12px', fontWeight: '300'}}>version 1.0</i></div>
    </section>
    <Footer/>
    </div>
  )
}

export default Legal_Four;