import React from 'react'
import headerIcon from "../../assets/images/Icono-App-8.webp";
import appstore from "../../assets/images/app_store_logo.webp";
import playstore from "../../assets/images/google_play_logo.webp";
import sliderBg from "../../assets/images/slider_bg.webp";
import homeImage from "../../assets/images/Home-Image_opt.webp";
import "../../styles/main.css";
import "../../styles/responsive.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import FooterEn from '../../components/footer/english';
import NavBarEn from '../../components/navbar/english';

const HomeEn = () => {
    
    return (
        <div className="home page-template page-template-homepage page-template-homepage-php page page-id-6 logged-in admin-bar elementor-default elementor-kit-732 elementor-page elementor-page-6 dialog-body dialog-buttons-body dialog-container dialog-buttons-container customize-support" style={{
            backgroundImage: `url('../../assets/images/home_bg_scaled.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100%',
        }}>
            <NavBarEn />
            <section className="main-slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-sm-5">
                            <div className=" wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms"
                                style={{ visibility: "visible", animationDuration: "1500ms", animationDelay: "0ms", animationName: "fadeInLeft", }}>
                                <h1 className="banertext">Banking <br /> made <br /><span id="spin"></span></h1>

                                <span>
                                    <h3 className="comingtext" style={{ color: "rgb(33,67,136)", }}>COMING SOON</h3>
                                </span>
                            </div>

                            <div className="botom_jion_img d-flex align-items-center wow fadeInRight pt-5 animated"
                                data-wow-delay="2ms" data-wow-duration="1500ms"
                                style={{ visibility: "visible", animationDuration: "1500ms", animationDelay: "2ms", animationName: "fadeInRight", }}>
                                <img width="92px" src={headerIcon} alt="nuestro header icon" />
                                <p>DOWNLOAD <br />
                                    <span className="color-pink">THE APP</span> NOW
                                </p>
                            </div>

                            <div className="col-6 pl-0 mt-5 download_btn wow fadeInLeft animated" data-wow-delay="4ms"
                                data-wow-duration="2000ms"
                                style={{ visibility: "visible", animationDuration: "2000ms", animationDelay: "4ms", animationName: "fadeInLeft" }}>
                                <a href="https://play.google.com/store/apps/details?id=com.nuestro.app" target="_blank" rel="noopener noreferrer"><img
                                    src={playstore} alt="nuestro in play store" /></a>
                                <a href="https://apps.apple.com/app/nuestro/id1670035380" target="_blank" rel="noopener noreferrer"><img className="mt-2"
                                    src={appstore} alt="nuestro in app store" /></a>
                            </div>

                        </div>

                        <div className="col-lg-7 col-sm-7">
                            <div className="right_slide">
                                <div className="globe wow fadeInRight animated" data-wow-delay="7ms" data-wow-duration="1500ms"
                                    style={{ visibility: "visible", animationDuration: "1500ms", animationDelay: "7ms", animationName: "fadeInRight" }}>
                                    <img src={sliderBg} alt="nuestro brand element" />
                                </div>
                                <div className="midllepart wow fadeInLeft animated" data-wow-delay="8ms" data-wow-duration="1500ms"
                                    style={{ visibility: "visible", animationDuration: "1500ms", animationDelay: "8ms", animationName: "fadeInLeft" }}>
                                    <img src={homeImage} width="100%" alt="nuestro home page banner" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
            <FooterEn />
        </div>
    )
}

export default HomeEn;